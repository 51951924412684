<script>
import {
  required, requiredIf, minValue, maxValue, minLength,
} from 'vuelidate/lib/validators';
import { isValue } from '@shared/config/vuelidate';
import { editor } from '@app/mixins/trainings';
import EditorLayout from '@app/layouts/EditorLayout.vue';
import QuizGenericEditor from '@app/components/QuizGenericEditor.vue';
import TrainingItemResourcesEditor from '@app/components/TrainingItemResourcesEditor.vue';
import TrainingItemEditorStatusField from '@app/components/TrainingItemEditorStatusField.vue';

export default {
  mixins: [editor],
  components: {
    EditorLayout,
    QuizGenericEditor,
    TrainingItemResourcesEditor,
    TrainingItemEditorStatusField,
  },
  i18n: {
    messages: {
      fr: {
        statusOption: {
          draft: 'Mettre le quiz en brouillon',
          published: 'Publier le quiz',
        },
      },
    },
  },
  form: {
    paginate: {
      switchLabel: 'Activer la pagination',
      type: 'switch',
      tooltip: `
        Divisez votre quiz en plusieurs "étapes"
        ou "pages", en précisant un nombre
        de questions affichées par page (renseignez 0
        pour afficher toutes les questions sur une seule page).
      `,
      tooltipAttrs: {
        position: 'is-top',
        multilined: true,
      },
      column: 'is-12 pt-0',
    },
    shuffle: {
      switchLabel: 'Activer l\'ordre aléatoire',
      type: 'switch',
      column: 'is-12 pt-0',
    },
    hide_answers: {
      switchLabel: 'Toujours cacher les bonnes réponses',
      type: 'switch',
      column: 'is-12 pt-0',
      tooltip: `
        En cochant cette case, les bonnes réponses à la fin du quiz
        seront cachées, même si l'apprenant réussi le quiz.
      `,
      tooltipAttrs: {
        multilined: true,
      },
    },
    questions_per_page: {
      label: 'Nombre de questions par page',
      type: 'number',
      column: 'is-12 pt-0',
      errors: ['required'],
      inputAttrs: {
        min: 0,
        step: 1,
      },
    },
    min_success_rate: {
      label: 'Taux minimum de réussite',
      tooltip: `
        Tant que l'apprenant n'aura pas atteint
        ce taux de réussite, il pourra recommencer
        le quiz, sans voir les bonnes réponses.
      `,
      tooltipAttrs: {
        multilined: true,
      },
      type: 'number',
      column: 'is-12 pt-0',
      errors: ['required'],
      inputAttrs: {
        min: 0,
        max: 100,
        step: 1,
      },
    },
    description: {
      label: 'Entrez la description de ce quiz',
      type: 'editor',
      column: 'is-12 pt-0',
      inputAttrs: {
        maxlength: 512,
      },
    },
    timer: {
      switchLabel: 'Activer le compte à rebours',
      type: 'switch',
      tooltip: `
    Si vous activez le compte à rebours,
    l'apprenant disposera d'un temps limité
    pour répondre aux questions du quiz.
    À la fin du temps, le quiz sera soumis même s'il est incomplet.
  `,
      tooltipAttrs: {
        position: 'is-top',
        multilined: true,
      },
      column: 'is-12 pt-0',
    },
    timer_value: {
      label: 'Durée du compte à rebours (en minutes)',
      type: 'number',
      column: 'is-12 pt-0',
      errors: ['required'],
      inputAttrs: {
        min: 1,
        step: 1,
      },
    },
    partial: {
      switchLabel: 'Activer la pioche de question',
      type: 'switch',
      tooltip: `
    Si vous activez la pioche de question, un quiz sera généré en piochant aléatoirement
    un nombre donné de questions parmi la liste totale des questions.
  `,
      tooltipAttrs: {
        position: 'is-top',
        multilined: true,
      },
      column: 'is-12 pt-0',
    },
    partial_count: {
      label: 'Nombre de questions à piocher',
      type: 'number',
      column: 'is-12 pt-0',
      errors: ['required'],
      inputAttrs: {
        min: 1,
        step: 1,
      },
    },
  },
  data() {
    return {
      trainingItem: {
        id: null,
        parent_id: null,
        type: 'QUIZ',
        name: '',
        draft: false,
        description: '',
        resources: [],
        quiz_submissions_count: 0,
        specific_content: {
          shuffle: false,
          hide_answers: false,
          questions_per_page: 0,
          min_success_rate: 50,
          timer_value: 0,
          partial_count: 0,
        },
      },
      paginate: false,
      trainingItemQuiz: [],
      trainingItemQuizIsValid: true,
      hasOptionsModalOpen: false,
      timer: false,
      partial: false,
    };
  },
  methods: {
    onPaginateChange() {
      if (this.paginate) {
        this.$nextTick(() => {
          this.$refs.questions_per_page.$refs.input.focus();
        });
      }
    },
    onTimerChange() {
      if (this.timer) {
        if (!this.$store.getters['auth/hasExpertPlan']) {
          this.$showMessage.goPro(null, null, { plan: 'EXPERT' });
          this.$nextTick(() => {
            this.timer = false;
          });

          return;
        }

        this.$nextTick(() => {
          this.$refs.timer_value.$refs.input.focus();
        });
      }
    },
    onPartialChange() {
      if (this.partial) {
        if (!this.$store.getters['auth/hasExpertPlan']) {
          this.$showMessage.goPro(null, null, { plan: 'EXPERT' });
          this.$nextTick(() => {
            this.partial = false;
          });

          return;
        }

        this.$nextTick(() => {
          this.$refs.partial_count.$refs.input.focus();
        });
      }
    },
    setTrainingItem(data, setQuiz = true) {
      this.trainingItem.id = data.id;
      this.trainingItem.name = data.name;
      this.trainingItem.parent_id = data.parent_id;
      this.trainingItem.draft = data.draft;
      this.trainingItem.description = data.description;
      this.trainingItem.resources = data.resources;
      this.trainingItem.content = data.content;
      this.trainingItem.specific_content = data.specific_content;
      this.trainingItem.quiz_submissions_count = data.quiz_submissions_count;

      this.paginate = (data.specific_content.questions_per_page > 0);
      this.timer = (data.specific_content.timer_value > 0);
      this.partial = (data.specific_content.partial_count > 0);

      if (setQuiz) {
        this.trainingItemQuiz = data.quiz_details;
      }

      this.$nextTick(() => (this.isDirty = false));

      return data;
    },
    async updateTrainingItem(trainingItem, trainingItemQuiz, silent = false) {
      this.isLoading = true;

      const { uuid } = this.currentTraining;
      const trainingItemId = this.trainingItem.id;

      if (trainingItem.quiz_submissions_count <= 0) {
        await this.$store.dispatch('trainings/updateItemQuiz', {
          uuid,
          trainingItemId,
          trainingItemQuiz,
        });
      }

      await this.$store.dispatch('trainings/updateItem', {
        uuid,
        trainingItemId,
        trainingItem,
      });

      if (!silent) {
        this.$showMessage.success();
      }

      this.isLoading = false;
    },
    scrollToQuestion(ref) {
      this.$scrollTo(ref, {
        container: this.$refs.layout.$refs.body,
      });
    },
    handleNoQuestionsError() {
      if (!this.trainingItem.draft && this.$v.trainingItemQuiz.$error) {
        this.$buefy.dialog.alert({
          type: 'is-danger',
          message: `
            Vous ne pouvez pas publier votre quiz sans
            y ajouter des questions, sauf si vous
            le mettez en brouillon.
          `,
        });
      }
    },
    saveIfValid(isLoading) {
      // set errors on quiz
      this.$refs.quizEditor.$v.$touch();
      const isValid = this.dataIsValid(isLoading);

      if (!isValid) {
        this.handleNoQuestionsError();
        this.$nextTick(() => this.$refs.layout.scrollInContainer('.has-error'));
        return;
      }

      this.save();
    },
    save(silent = false) {
      if (!silent) {
        this.setAutoUpdate(this.trainingItem.id);
      }

      const trainingItem = { ...this.trainingItem };
      trainingItem.content = JSON.stringify(trainingItem.content || []);
      const { uuid } = this.currentTraining;
      let trainingItemQuiz;

      if (trainingItem.quiz_submissions_count <= 0) {
        trainingItemQuiz = this.trainingItemQuiz.map((q, qk) => {
          q = { ...q, order: qk + 1 };
          delete q.isOpen;
          return q;
        });
      }

      if (!this.paginate) {
        trainingItem.specific_content.questions_per_page = 0;
      }

      if (!this.timer) {
        trainingItem.specific_content.timer_value = 0;
      }

      if (!this.partial) {
        trainingItem.specific_content.partial_count = 0;
      }

      if (!trainingItem.id) {
        this.isLoading = true;

        this.$store
          .dispatch('trainings/addItem', { uuid, trainingItem })
          .then((data) => this.setTrainingItem(data, false))
          .then(({ id }) => this.$router.push({
            name: 'training_uuid_quiz',
            params: { uuid, id },
            query: { ...this.$route.query, parentId: trainingItem.parent_id },
          }))
          .then((data) => {
            if (trainingItem.quiz_submissions_count <= 0) {
              return this.$store.dispatch('trainings/updateItemQuiz', {
                uuid,
                trainingItemId: this.trainingItem.id,
                trainingItemQuiz,
              });
            }

            return data;
          })
          .then(this.setTrainingItem)
          .then(() => this.$showMessage.success())
          .finally(() => (this.isLoading = false));

        return;
      }

      this.updateTrainingItem(trainingItem, trainingItemQuiz, silent);
    },
    addQuestion() {
      if (!this.$refs.quizEditor) {
        return null;
      }

      return this.$refs.quizEditor.addQuestion();
    },
    closeOptionsModal() {
      if (!this.$store.getters['auth/hasExpertPlan']) {
        if (this.timer) {
          this.timer = false;
          this.trainingItem.specific_content.timer_value = 0;
        }
        if (this.partial) {
          this.partial = false;
          this.trainingItem.specific_content.partial_count = 0;
        }
      }

      this.hasOptionsModalOpen = false;
      this.isDirty = true;
    },
  },
  validations() {
    let trainingItemQuiz = {};

    if (!this.trainingItem.draft) {
      trainingItemQuiz = {
        required,
        minLength: minLength(1),
      };
    }

    return {
      trainingItem: {
        name: { required },
        specific_content: {
          questions_per_page: {
            required: requiredIf(() => this.paginate),
            minValue: minValue(0),
          },
          min_success_rate: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
          timer_value: {
            required: requiredIf(() => this.timer),
            minValue: minValue(0),
          },
          partial_count: {
            required: requiredIf(() => this.partial),
            minValue: minValue(0),
            maxValue: maxValue(this.trainingItemQuiz.length),
          },
        },
      },
      trainingItemQuiz,
      trainingItemQuizIsValid: {
        isValue: isValue(true),
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created() {
    const promise = this.handleInitialLoading();

    if (promise) {
      promise.then(this.setTrainingItem);
    }
  },
};
</script>

<template>
  <EditorLayout ref="layout" class="page" :trainingItem="trainingItem">
    <template #sidenav>
      <b-skeleton v-if="initialLoading" height="300" />
      <b-tabs v-else v-model="activeTab" size="is-small" :animated="false" expanded>
        <b-tab-item label="Quiz">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-field label="Entrez le nom de ce quiz" v-bind="$getErrorProps($v.trainingItem.name, ['required'])">
                <b-input v-model="trainingItem.name" :has-counter="false" maxlength="128" autofocus
                  @keyup.native.enter="isDirty && saveIfValid(isLoading)" />
              </b-field>
            </div>
            <TrainingItemEditorStatusField class="column is-12 pt-0" v-model="trainingItem.draft">
              <template #draft>
                <span v-t="'statusOption.draft'" />
              </template>
              <template #published>
                <span v-t="'statusOption.published'" />
              </template>
            </TrainingItemEditorStatusField>
            <div class="column mb-5">
              <b-button type="is-success" outlined expanded @click="hasOptionsModalOpen = true">
                Voir plus de paramètres
              </b-button>

              <b-modal v-model="hasOptionsModalOpen" :width="520">
                <div class="box mb-0">
                  <h2 class="title is-5">
                    Paramètres du quiz
                  </h2>
                  <div class="columns my-5 is-multiline">
                    <div class="column" :class="$options.form.min_success_rate.column">
                      <BaseField v-model.number="trainingItem.specific_content.min_success_rate"
                        :field="$options.form.min_success_rate"
                        :v="$v.trainingItem.specific_content.min_success_rate" />
                    </div>
                    <div class="column" :class="$options.form.shuffle.column">
                      <BaseField v-model="trainingItem.specific_content.shuffle" :field="$options.form.shuffle" />
                    </div>
                    <div class="column" :class="$options.form.hide_answers.column">
                      <BaseField v-model="trainingItem.specific_content.hide_answers"
                        :field="$options.form.hide_answers" />
                    </div>
                    <div class="column" :class="$options.form.paginate.column">
                      <BaseField v-model="paginate" :field="$options.form.paginate" @input="onPaginateChange" />
                    </div>
                    <div v-show="paginate" class="column" :class="$options.form.questions_per_page.column">
                      <BaseField ref="questions_per_page"
                        v-model.number="trainingItem.specific_content.questions_per_page"
                        :field="$options.form.questions_per_page"
                        :v="$v.trainingItem.specific_content.questions_per_page" />
                    </div>

                    <div class="column" :class="$options.form.timer.column">
                      <BaseField v-model="timer" :field="$options.form.timer" @input="onTimerChange" />
                    </div>
                    <div v-show="timer" class="column" :class="$options.form.timer_value.column">
                      <BaseField ref="timer_value" :value="trainingItem.specific_content.timer_value / 60"
                        @input="trainingItem.specific_content.timer_value = $event * 60"
                        :field="$options.form.timer_value" :v="$v.trainingItem.specific_content.timer_value" />
                    </div>

                    <div class="column" :class="$options.form.partial.column">
                      <BaseField v-model="partial" :field="$options.form.partial" @input="onPartialChange" />
                    </div>
                    <div v-show="partial" class="column" :class="$options.form.partial_count.column">
                      <BaseField ref="partial_count" v-model.number="trainingItem.specific_content.partial_count"
                        :field="{
                          ...$options.form.partial_count,
                          inputAttrs: {
                            ...$options.form.partial_count.inputAttrs,
                            max: trainingItemQuiz.length
                          },
                          help: `Il y a actuellement ${trainingItemQuiz.length}
                        question${trainingItemQuiz.length > 1 ? 's' : ''} parmi lesquelles piocher.`,
                        }" :v="$v.trainingItem.specific_content.partial_count" />
                    </div>
                  </div>
                  <p class="field has-text-right">
                    <b-button type="is-primary" @click="closeOptionsModal" :disabled="isLoading">
                      Terminer
                    </b-button>
                  </p>
                </div>
              </b-modal>
            </div>
            <div class="column" :class="$options.form.description.column">
              <BaseField v-model="trainingItem.description" :field="$options.form.description" />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Questions">
          <p class="mb-3 has-text-centered">
            Vous avez la possibilité de réordonner les questions de votre quiz ici.
          </p>
          <draggable v-if="trainingItemQuiz.length" v-model="trainingItemQuiz"
            :disabled="trainingItem.quiz_submissions_count > 0">
            <a v-for="(q, qk) in trainingItemQuiz" :key="qk"
              class="mb-2 p-2 is-block bradius-4 border-dashed color-inherit break-words"
              @click.prevent="scrollToQuestion(qk)">
              <b-icon icon="grip-lines" />
              {{ q.question }}
            </a>
          </draggable>
          <b-button class="mt-5" icon-left="plus" expanded @click="addQuestion">
            Ajouter une question
          </b-button>
        </b-tab-item>
        <b-tab-item label="Annexes">
          <TrainingItemResourcesEditor :training="currentTraining" :training-item="trainingItem" />
        </b-tab-item>
      </b-tabs>
    </template>
    <template #header_right>
      <b-button v-if="pageURL" type="is-text" size="is-small" icon-left="eye" @click="onViewDraft">
        Voir la page
      </b-button>
      <b-button type="is-primary" size="is-small" :loading="isLoading" :disabled="!isDirty"
        @click="saveIfValid(isLoading)">
        Sauvegarder
      </b-button>
    </template>
    <template #footer>
      <b-button type="is-primary" size="is-small" :loading="isLoading" :disabled="!isDirty"
        @click="saveIfValid(isLoading)">
        Sauvegarder les modifications
      </b-button>
    </template>
    <template #main>
      <b-skeleton v-if="initialLoading" height="500" />
      <QuizGenericEditor v-else ref="quizEditor" class="container" v-model="trainingItemQuiz"
        :trainingItem="trainingItem" @dirty="isDirty = $event" @validation="trainingItemQuizIsValid = !$event.$invalid"
        @question-add="scrollToQuestion($event.ref)" />
    </template>
  </EditorLayout>
</template>
<style lang="scss" scoped>
.page {
  .container {
    max-width: 728px;
  }
}

.go-pro-wrapper {
  width: 100%;
  padding-bottom: 0.75rem;
}
</style>
